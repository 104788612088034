import styled from "styled-components";

export const CardContent = styled.div`
  min-width: 617px;
  height: 280px;
  /* min-height: 319px; */
  overflow: hidden;
  padding: 24px 33.5px 24px 0px;
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border: 1.8px solid #ededed;

  .card-head {
    display: flex;
    gap: 16px;
    align-items: center;

    &__num {
      min-width: 104px;
      height: 43px;
      background-color: #0074c9;
      font-size: 21px;
      font-family: "SpoqaHanSansNeo-Medium";
      line-height: 24.99px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    &__title {
      max-height: 43px;
      overflow: auto;

      span {
        font-size: 24px;
        line-height: 1.23;
        text-align: left;
        color: #0d0d0d;
        overflow: auto;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      @media (max-width: 540px) {
        overflow: hidden;
      }

      @media (max-width: 425px) {
        display: inline-flex;
        flex-direction: column;
        span {
          font-size: 20px;
          letter-spacing: normal;
        }
      }
    }
  }

  .card-content {
    font-size: 20px;
    color: #333;
    margin: 20px 0px 0px 29px;

    &__author {
      height: 56px;
      line-height: 56px;
      overflow: hidden auto;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &__saleprice {
      /* padding: 6.5px 0px; */
    }
    &__more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10.5px 0px;
    }

    &__place {
      margin-top: 8.5px;
      font-family: "SpoqaHanSansNeo-Light";
      font-size: 14px;
      text-align: right;
      line-height: 19.04px;
      color: #6d6d6d;
    }

    @media (max-width: 540px) {
      margin: 18px 0px 0px 26px;
      font-size: 20px;

      &__author {
        padding: 0px 0px 12.5px 0px;
        min-height: 37.5px;
      }

      &__saleprice {
        padding: 11.5px 0px;
      }

      &__more {
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        padding: 10.5px 0px 13.5px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }

  @media (max-width: 425px) {
    min-width: 100%;
    width: 100%;
    max-height: 347px;
    padding: 44px 34.1px 57.5px 0px;
  }
`;
