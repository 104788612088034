import { mainAxios } from "libs/axios";

export const categoryTermApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/terms/get-all-category`,
    notError: true,
    getError: true,
  });
};

interface ListTermProps {
  categoryCode: string;
}

export const listTermApi = (params: ListTermProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/terms/get-all`,
    notError: true,
    getError: true,
    params: params,
  });
};

interface GetContentTermProps {
  id: number;
}

export const getContentTermApi = (params: GetContentTermProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/terms/get-by-id`,
    notError: true,
    getError: true,
    params: params,
  });
};
