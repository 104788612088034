import styled, { css } from "styled-components";

export const CardContent = styled.div`
  flex: 0 0 calc(25% - 35.25px);

  @media screen and (max-width: 1280px) {
    flex: 0 0 calc(33% - 31.333333px);
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 calc(50% - 23.5px);
  }
  @media screen and (max-width: 540px) {
    flex: 0 0 calc(100%);
  }
  .container-card {
    height: 100%;
    border: 2px solid #ededed;
    padding: 0 20px 38px;

    display: flex;
    flex-direction: column;
  }
`;

export const CardContentImg = styled.div`
  width: 100%;
  /* height: 314px; */
  padding: 15% 0;
  background-color: #fff;
  overflow: hidden;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 295px;

  img {
    width: 200px;
    height: 200px;
    /* object-fit: fill; */
    object-fit: scale-down;
    @media screen and (max-width: 767px) {
      max-width: 180px;
      height: 180px;
    }
  }

  .ant-empty-description {
    color: #a3a1a1;
  }
`;

export const CardMeta = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
  /* margin-bottom: 40px; */
  overflow: hidden;
`;

export const CardTitle = styled.div`
  .author-name {
    color: #0d0d0d;
    font-family: "SpoqaHanSansNeo-Medium";
    font-size: 30px;
    line-height: 27px;
  }
  .title {
    color: #333;
    font-size: 21px;
    line-height: 27px;
    margin: 6px 0 20px;
  }
  .code {
    font-size: 18px;
    color: #0d0d0d;
    padding-bottom: 20px;
    text-align: right;
    min-height: 48px;
  }
  .title-max-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .title-max-line3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const Process = styled.div<{ textColor?: any }>`
  width: 100%;
  height: 25px;
  background-color: #f2f2f2;
  position: relative;
  display: flex;
  align-items: center;

  .ant-progress-inner {
    border-radius: 0px !important;
    .ant-progress-bg {
      height: 25px !important;
      border-radius: 0px !important;
    }
  }

  .infor-progress {
    height: 100%;
    position: absolute;
    right: 9px;
    font-family: "SpoqaHanSansNeo-Bold";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.45px;
    color: ${({ textColor }) => textColor ?? "#de8808"};
  }
`;

export const CardPrice = styled.span`
  width: 100%;
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0d0d0d;
  margin-top: auto;

  .old-price {
    color: #a7a7a7;
    font-size: 16px;
    text-decoration: line-through;
  }
`;

export const SaleEnd = styled.div`
  width: 100%;

  font-stretch: normal;
  font-style: normal;
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: auto;

  span {
    font-size: 18px;
    color: #6d6d6d;

    &.sale-end-bold {
      padding-left: 3px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const BtnGroup = styled.div``;

export const TagEvent = styled.div<{ noData?: boolean }>`
  margin: 25px 0 -18px;
  border-radius: 5px;
  background-color: #ff1744;
  width: 55px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 11px;
  font-weight: 600;
  align-self: flex-start;
  ${(props) => {
    if (props.noData) {
      return css`
        background-color: transparent;
      `;
    }
  }}
`;
