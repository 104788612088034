import { Button, Modal, Row } from "antd";
import React from "react";
import * as S from "./style";
import Popup_Head from "assets/images/_popup/popup_head.png";

interface PopupProps {
  visible: boolean;
  setVisible?: () => void;
  type?: "not_sale" | "default";
  contentNotice?: any;
  confirmEvent?: () => void;
  confirmGroupSale?: boolean;
  isWaitingBuy?: boolean;
  eventType?: string;
  isPageDrawText?: React.ReactNode;
  typeBuyGp?: string;
}

const Popup: React.FC<PopupProps> = ({
  visible,
  setVisible,
  type = "default",
  contentNotice,
  confirmEvent,
  confirmGroupSale,
  isWaitingBuy,
  eventType,
  isPageDrawText,
  typeBuyGp,
}) => {
  const handleRenderText = () => {
    if (["EVENT_RB", "EVENT_ARB"].includes(eventType ?? "")) {
      return (
        <h2>
          응모 신청완료했습니다.
          <br />
          마이페이지에서 확인할 수 <br />
          있습니다.
        </h2>
      );
    } else if (!isWaitingBuy) {
      return (
        <h2>
          {typeBuyGp === "EVENT" ? (
            "응모 완료되었습니다."
          ) : (
            <div>
              청약 신청이 완료되었습니다. <br />
              신청내역은 마이페이지 {">"} 신청내역에서 <br />
              확인하시기 바랍니다.
            </div>
          )}
        </h2>
      );
    } else {
      return (
        <h2>
          {contentNotice ?? (
            <span>
              대기 신청이 <br /> 접수되었습니다.
            </span>
          )}
        </h2>
      );
    }
  };

  return (
    <S.Modals
      bodyStyle={{ position: "relative", padding: 0 }}
      visible={visible}
      width={"max-content"}
      closable={false}
      footer={null}
      onCancel={() => setVisible?.()}
      centered
    >
      <S.Container className={`${type}`}>
        {/* <S.HeadPopup src={Popup_Head} alt="head-popup" /> */}
        <S.Content>
          {isPageDrawText ?? handleRenderText()}
          {/* {!isWaitingBuy ? (
              <h2>주문 완료되었습니다.</h2>
            ) : (
              <h2>
                {contentNotice ?? (
                  <span>
                    대기 신청이 <br /> 접수되었습니다.
                  </span>
                )}
              </h2>
            )} */}
          <Row justify="center">
            <Button type="primary" onClick={confirmEvent}>
              확인
            </Button>
          </Row>
        </S.Content>
      </S.Container>
    </S.Modals>
  );
};

export default Popup;
