/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-28 17:54:32
 * @modify date 2022-01-28 17:54:32
 * @desc [description]
 */

import { Select } from "antd";

import styled from "styled-components";

export const SelectCustom = styled(Select)`
  &.ant-select-single {
    min-width: 69.5px;
    outline: none;
    font-size: 18px;
    font-family: "SpoqaHanSansNeo-Bold";
    background-color: transparent;

    .ant-select-selector {
      padding: 0px;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #e5e5e5 !important;
      background-color: transparent;
      outline: none !important;
      box-shadow: none !important;

      .ant-select-selection-item {
        color: #0d0d0d;
      }
    }

    .ant-select-arrow {
      font-size: 18px;
      display: flex;
      align-items: center;
      span {
        color: #0d0d0d;
      }
    }
  }
`;

export const SelectItem = styled(Select.Option)`
  color: #0d0d0d;
`;
