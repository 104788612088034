import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import * as S from "./style";

type dataMenu = {
  title: string;
  url: string;
};
interface Props {
  data: dataMenu[];
  isShowMultipleLang?: boolean;
}

function TabHeader({ data, isShowMultipleLang }: Props) {
  const [active, setActive] = useState(data[0].url);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  useMemo(() => setActive(location?.pathname), [location?.pathname]);

  return (
    <S.Wrapper className="wrapper-menu">
      <S.Container>
        {data.map((item, index) => (
          <S.Tab
            key={index}
            onClick={() => {
              setActive(item.url);
              history.push(item.url);
            }}
            active={
              active === item.url || location?.pathname?.includes(item.url)
            }
          >
            {/* {item.title} */}
            {isShowMultipleLang ? item.title : t(item.title)}
          </S.Tab>
        ))}
      </S.Container>
    </S.Wrapper>
  );
}

export default TabHeader;
