import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; //
import types from "store/actions/types";
// import { UrlInternal } from "common/constants/endpoints";

import * as S from "./style";

interface Props {
  placeHl: string;
}

const SearchLeftMenu = ({ placeHl }: Props) => {
  const dispatch = useDispatch();
  const { searchValue } = useSelector((state: any) => state.searchBar);

  useEffect(() => {
    dispatch({
      type: types.SEARCH_BAR,
      isOpen: false,
      searchValue: "",
    });
  }, []);

  /** Get Search Value */
  const getSearchValue = () => {
    const v: any = document.getElementById("searchInput");
    dispatch({
      type: types.SEARCH_BAR,
      isOpen: false,
      searchValue: v?.value.trim() ?? "",
    });
  };

  return (
    <S.Content className="search-on-left-menu">
      <S.SearchInput
        id="searchInput"
        placeholder={placeHl}
        onBlur={getSearchValue}
        onKeyDown={(e: any) => {
          if (e?.keyCode === 13) getSearchValue();
        }}
        // defaultValue={searchValue}
        autoComplete="off"
      />
      <S.SearchButton
        type="text"
        // shape="round"
        icon={<span className="ti ti-search"></span>}
        onClick={getSearchValue}
      />
    </S.Content>
  );
};

export default SearchLeftMenu;
