import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .btn-count {
    height: 45px;
    line-height: 45px;
    text-align: center;
    width: 48px;
    color: #000;
    border: 1px solid #e1e1e1;
    font-size: 23px;
    cursor: pointer;
    @media (max-width: 540px) {
      font-size: 20px;
      height: 38px;
      width: 38px;
      line-height: 38px;
    }
  }

  .controls-input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1px;
    display: flex;
    flex-direction: column;
    height: 32px;

    > button {
      background-color: #fff;
      height: 16px;
      padding: 4px 7px;
      color: #000;
      font-size: 6px;
      border: 1px solid #d9d9d9;
      &:first-child {
        border-bottom: none;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.45);
        cursor: not-allowed;
      }
    }
  }

  .ant-input-number {
    width: 100px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    @media (max-width: 540px) {
      width: 75px;
    }
    &.ant-input-number-readonly .ant-input-number-handler-wrap {
      display: block;
    }

    .ant-input-number-input {
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
      border-radius: 0;
      text-align: center;
      color: #000;
      font-size: 23px;
      height: 45px;
      @media (max-width: 540px) {
        font-size: 18px;
        height: 38px;
      }
    }
    .ant-input-number-handler-wrap {
      display: none;
      opacity: 0;
      /* opacity: 1;
      border-radius: 0;
      border-left: 1px solid #000;
      .ant-input-number-handler {
        path {
          fill: #000;
        }
      }
      .ant-input-number-handler-down-disabled,
      .ant-input-number-handler-up-disabled {
        path {
          fill: #6d6d6da1;
        }
      } */
    }
  }
`;
