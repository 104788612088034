import { mainAxios } from "libs/axios";

/**get list owner ship */
interface listOwnershipProps {
  querySearch?: string;
}

export const ownerShipApi = (params: listOwnershipProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/gp-ownership`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    notError: true,
  });
};

/**List owner ship props type */
interface detailOwnerShipProps {
  id: number;
}
export const detailOwnerShipApi = (params: detailOwnerShipProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/gp-ownership-detail`,
    requiresToken: true,
    params: params,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const purchaseStatsApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/gp-stats`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**purchase plan props type */
interface purchasePlanProps {
  page?: number;
  size?: number;
  status?: string;
  listStatus?: any;
  querySearch?: string;
  filterType?: string;
}

export const purchasePlanApi = (params: purchasePlanProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/gp/use-gp-plan`,
    requiresToken: true,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
