import { Select } from "antd";
import styled from "styled-components";

export const Selects = styled(Select)`
  /* width: calc(100% - 50px); */
  /* min-width: 160px; */

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #959595 !important;
    box-shadow: unset !important;
    padding: 0 !important;
    /* font-family: "SpoqaHanSansNeo-Bold"; */
    font-size: 14px;
    color: #0d0d0d;
    min-width: 80px;
    margin-right: 18px;
  }
  svg path {
    fill: #0d0d0d;
  }
`;
