import types from "../actions/types";

const defaultState = {
  typeLoginSns: "",
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.TYPE_LOGIN_SNS:
      return {
        ...state,
        typeLoginSns: action.typeLoginSns,
      };
    default:
      return state;
  }
};
