/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 11:53:41
 * @modify date 2022-01-24 11:53:41
 * @desc [description]
 */

import React from "react";
import { Empty, Image } from "antd";
import { useHistory } from "react-router";
/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Images */
import art_market_slider_1 from "assets/images/_art_market_slider/art_market_slider_1.png";

/** Local Style */
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import moment from "moment-timezone";

interface Props {
  image: string;
  artName?: string;
  title: string;
  priceSale: number;
  price: number;
  className?: string;
  isFavorite?: boolean;
  linkToDetail?: () => void;
  isOuStock?: boolean;
  saleOffPercent?: number;
  isPageDraw?: boolean;
  allData?: any;
}

const ArtCardItemFavorite = ({
  image = art_market_slider_1,
  artName,
  title,
  priceSale,
  price,
  className,
  // isFavorite,
  linkToDetail,
  isOuStock,
  saleOffPercent,
  isPageDraw,
  allData,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const EVENT_FINISH = "FINISH";

  return (
    <S.CardContent className={`art-card-item ${className}`}>
      <div
        className={`container ${
          allData?.eventStatus === EVENT_FINISH ? "container-disabled" : ""
        }`}
      >
        {allData?.eventStatus === EVENT_FINISH && (
          <div className="end-draw">이벤트 마감</div>
        )}
        <div className="image-card" onClick={linkToDetail}>
          {image ? (
            <Image src={image} alt={"art"} preview={false} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </div>

        {isPageDraw ? (
          <>
            <S.CardTitleDraw>{allData?.title}</S.CardTitleDraw>
            <S.CardPrice>
              <span>
                {moment(allData?.startDate).format("YYYY.MM.DD")} ~{" "}
                {moment(allData?.endDate).format("YYYY.MM.DD")}
              </span>
            </S.CardPrice>
          </>
        ) : (
          <>
            <S.CardArtName>{artName}</S.CardArtName>
            <S.CardTitle>{title}</S.CardTitle>
            <S.CardPrice>
              {isOuStock ? (
                <>{t("SOLD_OUT")}</>
              ) : (
                <>
                  {priceSale !== price && (
                    <>
                      <span className="old-price">
                        {moneyConvertLang(price)}
                      </span>
                    </>
                  )}
                  <span className={priceSale !== price ? "current-price" : ""}>
                    {moneyConvertLang(priceSale)}
                  </span>
                  {priceSale !== price && (
                    <span className="sale-person">-{saleOffPercent}%</span>
                  )}
                </>
              )}
            </S.CardPrice>
          </>
        )}
        {/* </S.CardMeta> */}
      </div>
    </S.CardContent>
  );
};

export default ArtCardItemFavorite;
