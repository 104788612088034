import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./style";

function FooterMain() {
  const history = useHistory();
  const { t } = useTranslation();
  const { dataCategoriesTerm } = useSelector(
    (state: any) => state.dataCategoriesTerm
  );

  return (
    <S.Wrapper id="footer">
      <S.Container>
        <div className="step">
          <S.Title>COMPANY</S.Title>
          <S.Box>
            <S.Text>{t("FOOTER_COMPANY_TITLE")}</S.Text>
            <S.Text>{t("FOOTER_COMPANY_TITLE1")}</S.Text>
            <S.Text>주소</S.Text>
            <S.Text>서울: 06182 서울 강남구 영동대로 407, 코니빌딩 4층</S.Text>
            <S.Text>
              충남: 31470 충남 아산시 배방읍 희망로46번길 45-11, 405호
            </S.Text>
          </S.Box>

          <S.Box className="mt-18">
            <S.Text>통신판매업 신고번호 : 2021-충남아산-0558호</S.Text>
            <S.Text>개인정보관리책임자 : 김재욱</S.Text>
          </S.Box>

          <S.Text className="text-modified">
            사업자등록번호 : 664-88-00573
          </S.Text>

          <S.ContainerTerm>
            {dataCategoriesTerm?.map((dt: any, i: any) => (
              <div key={i} onClick={() => history.push(dt.location)}>
                {dt.title}
              </div>
            ))}
          </S.ContainerTerm>
          <S.TextMin>© Yeolmaecompany. all rights reserved</S.TextMin>
        </div>

        <div className="step">
          <S.Title className="title-modified">CONTACT</S.Title>
          <S.Box>
            <S.Text className="max-content">
              Company Mail (사업제휴 / 회사문의)
            </S.Text>
            <S.Text>info@artnguide.com</S.Text>
          </S.Box>
          <S.Box className="mg">
            <S.Text>CS Mail (고객CS문의)</S.Text>
            <S.Text>cs@artnguide.com</S.Text>
          </S.Box>

          <S.Title className="title-modified">HOURS</S.Title>
          <S.Text>
            {t("FOOTER_CONTACT")} 10:00~18:00 ({t("FOOTER_CONTACT1")}{" "}
            12:00~13:00)
          </S.Text>

          <S.ContainerTerm className="isMobile">
            {dataCategoriesTerm?.map((dt: any, i: any) => (
              <div key={i} onClick={() => history.push(dt.location)}>
                {dt.title}
              </div>
            ))}
          </S.ContainerTerm>
          <S.TextMin className="isMobile">
            © Yeolmaecompany. all rights reserved
          </S.TextMin>
        </div>
      </S.Container>
    </S.Wrapper>
  );
}

export default FooterMain;
