import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 1398px;
  width: 100%;
  /* height: 111.5px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  gap: 50px;
  overflow: auto;
  height: 114px;
  /* margin: 0 15px; */
  @media (max-width: 1024px) {
    gap: 30px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
  @media (max-width: 540px) {
    height: 80px;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: #0d0d0d;
  cursor: pointer;
  white-space: nowrap;
  padding: 0px 30px;
  ${(props) => {
    if (props.active) {
      return css`
        border-radius: 23px;
        border: solid 1px #41adac;
        background-color: #fff;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-family: "SpoqaHanSansNeo-Medium";
        padding: 0px 35px;

        color: #41adac;
      `;
    }
  }}

  &:hover {
    color: #41adac;
  }

  @media (max-width: 360px) {
    padding: 0px 15px;
  }
`;
