import styled from "styled-components";
import bg from "assets/images/border.png";

export const Container = styled.div`
  width: 501px;
  height: 595px;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 490px;
    max-height: 585px;
    border: solid 1px #ad9a6d;
  }
`;

export const Content = styled.p`
  font-family: SpoqaHanSansNeo-Light;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.55px;
  text-align: left;
  color: #111;
  margin: 30px 0 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
