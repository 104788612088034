import { mainAxios } from "libs/axios";

/** Get Purchase Props Type */
interface GetDataNoticeTypes {
  size: number;
  categoryCode: string;
  page?: string;
  query?: string;
  hashtag?: string;
  startTime?: string | "";
  endTime?: string | "";
  onlyOnePerCate?: string;
  pinned?: boolean;
}

export const getDataNotice = (payload: GetDataNoticeTypes) => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/article/get-all",
    notError: true,
    getError: true,
    payload: {
      startTime: "",
      endTime: "",
      ...payload,
      ...{ size: String(payload.size) },
    },
  });
};
export const getListGroupSale = async () => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/faq/get-all",
    payload: {
      page: "",
      size: "100",
      endTime: "",
      query: "",
      startTime: "",
      categoryCode: "GUIDE",
    },
  });
};
export const getDetailGroupSale = async (id: number) => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/faq/get-by-id",
    payload: {
      faqId: Number(id),
    },
  });
};
export const getHintData = async (id: number) => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/article/next-prev",
    payload: {
      articleId: Number(id),
    },
  });
};

/**get category api article */
interface GetDataCategoryArticle {
  size?: string;
  categoryCode?: string;
  page?: string;
}

export const getCategoryArticleApi = async (
  payload: GetDataCategoryArticle
) => {
  return mainAxios.postRequest({
    requiresToken: true,
    url: "/article/get-all-category",
    payload: payload,
  });
};
