/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-02-14 15:04:51
 * @modify date 2022-02-14 15:04:51
 * @desc [description]
 */

import { mainAxios } from "libs/axios";

interface MaketDetail {
  id: number;
}

export const getMarketDetail = (id: MaketDetail) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/market/product-details?id=${id}`,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
//draw
interface MaketDetailDraw {
  id: number;
}

export const getMarketDetailDraw = (id: MaketDetailDraw) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/draw-event/event-detail?id=${id}`,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
    getError: true,
    notError: true,
  });
};
/** ArtMarket List Product  */
interface ArtListProduct {
  /**  1: 아트상품 - hàng nghệ thuật artnguide hợp tác,
   *   2: 원화 - tranh gốc ,
   *   3: 판화 - tranh khắc ,
   *   4: Print - tranh in */
  categoryId?: number;
  size?: number;
  page?: number;
  typeSearch?: "AVAILABLE" | "OUTSTOCK"; // AVAILABLE: Còn hàng, OUTSTOCK:Hết hàng
  querySearch?: string; // Seach theo tên tác giả, tác phẩm
  startPrice?: number;
  endPrice?: number;
}

export const listArtMarket = (params: ArtListProduct) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/market/products`,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

//draw
interface listArtMarketDrawProps {
  page: number;
  size: number;
  typeSearch?: string;
}

export const listArtMarketDraw = (params: listArtMarketDrawProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/draw-event/find`,
    params: params,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** ArtMarket Order  */
interface MaketOrder {
  productId: number;
  unitBuy: number;
  pointUsing: number;
  getInvoice: boolean;
  invoiceType: string;
  invoiceInfo: string;
  bankChoice: string;
  bankSenderName: string;
  buyerAddress: string;
  buyerEmail: string;
  buyerPhone: string;
  buyerZipcode: string;
  receiverAddress: string;
  receiverPhone: string;
  receiverZipcode: string;
  note: string;
}

export const postMarketOrder = (payload: MaketOrder) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/market/order-product`,
    payload: payload,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/**artmarket order draw */
/** ArtMarket Order  */
interface MaketOrderDraw {
  drawEventId: number;
  phone: string;
  email: string;
  address: string;
  note: string;
  name: string;
  zipCode: string;
  receiverPhone: string;
  receiverAddress: string;
  receiverZipCode: string;
  receiverName: string;
}

export const postMarketOrderDraw = (payload: MaketOrderDraw) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/draw-event/user-order/apply`,
    payload: payload,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

/** ArtMarket Restock  */
interface MaketReStock {
  productId: number;
  amount: number;
  phone: any;
}

export const postMarketRestock = (payload: MaketReStock) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/market/create-restock-req`,
    payload: payload,
    requiresToken: true,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};

export const getCategoriesMarketApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/market/categories`,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
