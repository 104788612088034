import styled from "styled-components";

import { Pagination } from "antd";
export const PaginationStyled = styled(Pagination)`
  .ant-pagination-item {
    border: none;
    background-color: unset;
  }
  .ant-pagination-item-active {
    order: solid 1px #41adac !important;
    border-radius: 50%;
    a {
      color: #0d0d0d;
    }
  }
`;

export const Item = styled.div`
  width: 38px;
  height: 38px;
  padding: 8px 10px;
  border: solid 1px #0d0d0d;
  color: #0d0d0d;
`;
