import React from "react";
import { Empty, Image, Progress } from "antd";

/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Local Style */
import * as S from "./style";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import { timeNewGp } from "common/constants/timeNewGP";

interface Props {
  image: string;
  title: string;
  author: string;
  className?: string;
  targetPercent?: number;
  currentMoney?: number;
  startDate?: string;
  endDate?: string;
  data?: any;
}

const ArtCardItem = ({
  image,
  title,
  className,
  targetPercent = 0,
  currentMoney = 0,
  startDate,
  author,
  endDate,
  data,
}: Props) => {
  const { t } = useTranslation();
  const isNewGP = moment(startDate) > moment(timeNewGp);

  return (
    <S.CardContent className={`art-card-item ${className}`}>
      <div className="container-card">
        <S.CardContentImg>
          {image ? (
            <Image src={image} alt={"art"} preview={false} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </S.CardContentImg>

        <S.CardTitle>
          <div className="code">{data?.cpCodeV3}</div>
          <div className="author-name">{author}</div>
          <div
            className={`title ${data?.gpVer === "V1" && "title-max-line"} ${
              data?.gpVer === "V3" && "title-max-line3"
            }`}
          >
            {title}
          </div>
        </S.CardTitle>

        <S.SaleEnd>
          <div>
            <span>{isNewGP ? "총 발행금액" : "공동구매 구매액"}</span>
            <span className="sale-end-bold">
              {moneyConvertLang(currentMoney)}
            </span>
          </div>
          <div>
            <span> {isNewGP ? "청약 마감일" : "공동구매 종료일"}</span>
            <span className="sale-end-bold">
              {moment(endDate).format("YYYY.MM.DD")}
            </span>
          </div>
        </S.SaleEnd>
      </div>
    </S.CardContent>
  );
};

export default ArtCardItem;
