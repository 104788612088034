import React from "react";
import ReactDOM from "react-dom";
import * as S from "../style";
// import { ReactComponent as IconClose } from "assets/icon/close.svg";
import { CloseOutlined } from "@ant-design/icons";
import { Typography } from "antd";
const { Text } = Typography;

export interface ModalProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  onBack?: () => void;
  title?: any;
  children?: any;
  footer?: boolean;
  okText?: string;
  cancelText?: string;
  checkBack?: boolean;
}

const Default = ({
  visible,
  onOk,
  onCancel,
  title,
  children,
  footer,
  okText,
  cancelText,
  onBack,
  checkBack,
}: ModalProps) => {
  return visible
    ? ReactDOM.createPortal(
        <>
          <S.ModalOverlay />
          <S.ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <S.Modal className={"height-default"}>
              <S.ModalHeader className={"header"}>
                <Text className={"title"}>{title}</Text>
                {onCancel && (
                  <S.ModalCloseButton
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => onCancel?.()}
                  >
                    {/* <IconClose fill="#0D0D0D" width="22px" height="22px" /> */}
                    <CloseOutlined
                      twoToneColor={"#0D0D0D"}
                      style={{ width: "22px", height: "22px" }}
                    />
                  </S.ModalCloseButton>
                )}
              </S.ModalHeader>
              <S.ModalContainer>
                <S.ModalContent className={"center"}>{children}</S.ModalContent>
                {footer && (
                  <S.FooterModal>
                    <S.ButtonCancel
                      onClick={() => (checkBack ? onBack?.() : onCancel?.())}
                    >
                      {cancelText ? cancelText : "CANCEL"}
                    </S.ButtonCancel>
                    <S.ButtonStyled
                      onClick={() => onOk?.()}
                      className="payment"
                    >
                      {okText ? okText : "PAYMENT"}
                    </S.ButtonStyled>
                  </S.FooterModal>
                )}
              </S.ModalContainer>
            </S.Modal>
          </S.ModalWrapper>
        </>,
        document.body
      )
    : null;
};

export default Default;
