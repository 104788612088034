import { categoryTermApi } from "apis/agree_term";
import types from "./types";

const getCategoriesTerm = () => async (dispatch: any) => {
  try {
    const { data }: any = await categoryTermApi();

    if (data) {
      dispatch({
        type: types.GET_CATEGORY_TERM,
        dataCategoriesTerm: data.datas,
      });
    }
  } catch (error) {}
};
export default { getCategoriesTerm };
