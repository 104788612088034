import { Button } from "antd";
import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  transition: all 1s;
  @media screen and (max-width: 540px) {
    max-width: 530px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-family: "SpoqaHanSansNeo-Regular";
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #0d0d0d;

  &:active,
  :focus {
    outline: none;
  }

  &::placeholder {
    font-family: "SpoqaHanSansNeo-Light";
    font-size: 14px;
    font-weight: 300;
    color: #959595;
  }
`;

export const SearchButton = styled(Button)`
  width: auto;
  height: auto;

  &:active,
  :hover,
  :focus {
    background-color: transparent;
  }

  span {
    font-size: 18px;
    color: #6d6d6d;
  }
`;
