import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
  &.footer-none {
    display: none;
  }
`;

export const Container = styled.div`
  margin: 0px;
  width: 100%;
  max-width: 1350px;
  padding: 75px 0 60px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  .step {
    padding: 0 2%;

    @media screen and (max-width: 1280px) {
      justify-content: unset;
      gap: 2%;
      padding: 0px;
      &:first-child {
        padding: 0 0 0 20px;
      }
      &:last-child {
        padding: 0 20px 0 0;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 40px;
      margin-top: 46px;
      &:first-child {
        padding: 0 40px;
      }
      &:last-child {
        padding: 0 40px;
      }
    }
    @media screen and (max-width: 540px) {
      padding: 0 25px;
      &:first-child {
        padding: 0 25px;
      }
      &:last-child {
        padding: 0 25px;
      }
    }
    @media screen and (max-width: 360px) {
      padding: 0 2px 0 15px;
      &:first-child {
        padding: 0 2px 0 15px;
      }
      &:last-child {
        padding: 0 2px 0 15px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 25px 0 60px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  &.box-modified {
    /* gap: 12px; */
    margin-bottom: 26px;
  }
  &.mt-18 {
    margin-top: 18px;
  }

  &.mg {
    margin: 30px 0 45px;
  }
`;

export const Title = styled.div`
  font-family: "SpoqaHanSansNeo-Medium";
  font-size: 21px;
  line-height: 24.99px;
  color: #6d6d6d;
  margin-bottom: 19px;
  &.title-modified {
    margin-bottom: 13px;
  }
`;

export const Text = styled.div`
  font-size: 17px;
  line-height: 20.06px;
  color: #6d6d6d;
  &.text-modified {
    margin-top: 22px;
  }

  &.stock-company {
    margin-top: 25px;
  }

  &.max-content {
    width: max-content;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.7px;
  }
`;

export const TextModified = styled.div`
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: -0.9px;
  color: #6d6d6d;
  margin: 43px 0 9px;
  > span {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.27;
    letter-spacing: -0.75px;
  }
  @media screen and (max-width: 360px) {
    padding-right: 90px;
  }
  > div {
    cursor: pointer;
  }
`;

export const TextMin = styled.div`
  font-family: "SpoqaHanSansNeo-Light";
  font-size: 16px;
  line-height: 19.52px;
  color: #8a8a8a;

  &.isMobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
    display: none;
  }
`;

export const ContainerTerm = styled.div`
  display: flex;
  align-items: center;
  margin: 70px 0 13px;
  flex-wrap: wrap;
  gap: 0 19px;
  @media (max-width: 768px) {
    display: none;
  }

  &.isMobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  > div {
    font-family: "SpoqaHanSansNeo-Medium";
    font-size: 18px;
    line-height: 21.96px;
    color: #6d6d6d;
    position: relative;
    cursor: pointer;

    &::before {
      content: "|";
      position: absolute;
      right: -10px;
      top: -2px;
    }

    &:last-child {
      &::before {
        content: "";
      }
    }
  }
`;
