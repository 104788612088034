/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-19 14:33:01
 * @modify date 2022-01-19 14:33:01
 * @desc [description]
 */

import { Button } from "antd";
import styled from "styled-components";

interface ItemProps {
  bg?: any;
}

export const SaleTogetherContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  .box-time {
    display: inline-block;
    margin-left: 30px;
    .time {
      display: inline-block;
      text-align: center;
      border-radius: 5px;
      border: solid 1px #0d0d0d;
      width: 36px;
      height: 32px;
      line-height: 32px;
      margin-left: 15px;
      position: relative;
      font-family: "SpoqaHanSansNeo-Bold";
      font-size: 20px;
      font-weight: 600;
      &::after {
        content: ":";
        position: absolute;
        right: -11px;
        top: 0;
        bottom: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.ui-768 {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  /* @media screen and (max-width: 768px) {
    display: none;
  } */
`;

export const TitleContent = styled.span`
  width: 100%;
  font-size: 37px;
  font-family: "SpoqaHanSansNeo-Medium";
  text-overflow: ellipsis;
  overflow: auto;
  word-break: break-all;
  color: #0d0d0d;
  line-height: 45px;
`;

export const LeftInfo = styled.div<{ bg?: any }>`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 50%;
  overflow: hidden;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 100%;
  background-position: center;
  object-fit: contain;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  justify-content: center;
  /* padding: 30px; */

  @media screen and (max-width: 1024px) {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 650px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 425px) {
    height: unset;
  }
`;

export const ArtImage = styled.img`
  transition: all 0.3s;
  transition-timing-function: ease;
  object-fit: contain;
  max-width: 100%;
  width: 100%;

  /* width: 100%; */
  /* object-fit: cover; */
  /* height: 100%; */
  /* max-height: 425px; */

  /* @media screen and (max-width: 1024px) {
    object-fit: contain;
  }

  @media screen and (max-width: 425px) {
    object-fit: contain;
  } */
`;

export const InfoData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* margin-top: 55px; */
  width: calc(100% - 200px);
  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    width: 100%;
    flex-direction: row !important;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 42.1px;

  .box-timer {
    margin-left: auto;
    .time-end {
      text-align: right;
    }

    @media (max-width: 540px) {
      margin-left: initial;
      .time-end {
        text-align: left;
      }
    }
  }

  .ant-progress-inner {
    max-width: 564px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    width: 100%;
    margin-left: 0px;
  }

  .box-content {
    display: flex;
    flex-direction: column;
    gap: 43px;
    @media screen and (max-width: 768px) {
      gap: 24px;

      .box-content-item {
        > div {
          display: inline-block;
        }
      }
    }

    .title {
      color: #0d0d0d;
      font-size: 30px;
      font-family: "SpoqaHanSansNeo-Medium";
      @media (max-width: 768px) {
        font-size: 28px;
        padding-right: 5px;
      }
      @media (max-width: 360px) {
        font-size: 24px;
      }
    }
    .title2 {
      color: #0d0d0d;
      font-size: 28px;
      font-family: "SpoqaHanSansNeo-Medium";
      @media (max-width: 768px) {
        font-size: 28px;
      }
      @media (max-width: 360px) {
        font-size: 24px;
      }
    }

    .text-normal {
      color: #0d0d0d;
      font-size: 22px;
      font-family: "SpoqaHanSansNeo-Regular";
    }
    .text-bold {
      color: #0d0d0d;
      font-size: 22px;
      font-family: "SpoqaHanSansNeo-Regular";
      @media (max-width: 540px) {
        font-size: 18px;
        /* padding-right: 15px; */
      }
    }
    .text-small {
      color: #6d6d6d;
      font-size: 14px;
      font-family: "SpoqaHanSansNeo-Regular";
      @media (max-width: 540px) {
        display: block;
        text-align: right;
        font-size: 12px;
      }
    }

    .time {
      > span {
        color: #6d6d6d;
        font-size: 20px;

        @media (max-width: 768px) {
          font-size: 16px;
        }

        &.time-countdown {
          color: #056fda;
        }
      }
    }

    .time-end {
      color: #6d6d6d;
      font-size: 14px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .price {
      display: flex;
      justify-content: space-between;

      > div {
        color: #0d0d0d;
        font-size: 22px;
        @media (max-width: 540px) {
          font-size: 18px;
        }
        .price-bold {
          font-family: "SpoqaHanSansNeo-Medium";
          color: #056fda;
          padding-left: 5px;
          @media (max-width: 768px) {
            padding-left: 0px;
          }
        }
      }
    }

    .box-select {
      margin-left: auto;
      .item-select {
        display: flex;
        align-items: center;
        gap: 16px;

        .item-select-text {
          color: #0d0d0d;
          font-size: 22px;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
        .item-select-qty {
          color: #0d0d0d;
          font-size: 22px;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
      .note-select {
        color: #056fda;
        font-size: 14px;
        line-height: 22.14px;
        text-align: right;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  .box-content2 {
    display: flex;
    flex-direction: column;
    gap: 23px;

    .item-content2 {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (max-width: 768px) {
      gap: 18px;
    }

    @media (max-width: 540px) {
      gap: 15px;
      .item-content2 {
        .text-normal {
          font-size: 18px;
        }
      }
    }
  }
  .box-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    border-top: 1px solid #000;
    margin-top: 16px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      align-items: flex-start;
    }

    .title-total {
      color: #0d0d0d;
      font-family: "SpoqaHanSansNeo-Medium";
      font-size: 22px;
    }
    .sub-total {
      color: #6d6d6d;
      font-size: 20px;
      /* margin-bottom: 3px; */
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .price-total {
      color: #0d0d0d;
      font-family: "SpoqaHanSansNeo-Bold";
      font-size: 30px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    .value-price {
      color: #0d0d0d;
      font-size: 22px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .box-total-item {
      display: flex;
      gap: 13px;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
        align-items: flex-end;
        .sub-total {
          order: 2;
          margin-bottom: 0;
          text-align: right;
        }
      }
    }
  }
`;

export const SubTitleContent = styled.span`
  font-size: 18px;
  font-family: "SpoqaHanSansNeo-Light";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  color: #cf9305;
`;

export const InfoTitle = styled.span`
  font-family: "SpoqaHanSansNeo-Light";
  font-size: 22px;
  font-weight: 300;
  line-height: 1.23;
  color: #6d6d6d;

  .box-answer {
    font-family: "SpoqaHanSansNeo-Light";
    position: relative;
    display: inline-block;

    .img-answer {
      position: absolute;
      right: -20px;
      top: -24px;
      cursor: pointer;
      &:hover {
        .text-answer {
          display: block;
        }
      }
      img {
        max-height: 25px;
      }
      .text-answer {
        display: none;
        position: absolute;
        top: 3px;
        left: 25px;
        width: max-content;
        font-size: 19px;
        font-family: "SpoqaHanSansNeo-Light";
      }
    }
  }

  &.progress {
    margin-top: 29px;
    color: #056fda;
  }

  &.amount-total {
    margin: 25px 0px 0px 0px;
  }

  &.time-remaining {
    margin: 22px 0 55px;
    &.mrb-50 {
      /* margin: 0px 0px 50px 0px; */
      margin: 0px 0px 23px 0px;
    }
  }

  &.target {
    margin: 10px 0;
  }

  &.saleEnd {
    margin: 24px 0px 0px 0px;
  }

  .text-medium {
    font-family: "SpoqaHanSansNeo-Medium";
  }

  &.modified {
    margin-bottom: 27px;
    display: flex;
    align-items: center;
  }

  .quantity {
    font-family: "SpoqaHanSansNeo-Light";
  }
`;

export const ActionButton = styled(Button)`
  /* width: 330px; */
  width: 100%;
  height: 65px;
  background: #41adac;
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: 1px solid #d9d9d9;
  border-color: #41adac;

  &.action-button2 {
    margin-top: 16px;
  }

  &:hover,
  :active,
  :focus {
    background: #41adac;
    border-color: #41adac;
    color: #ffffff;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
  }

  &[disabled] {
    color: #ffffff;
    background: #f3f3f3;
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const ListImage = styled.div`
  width: 186.3px;
  /* height: auto; */
  overflow-wrap: unset;
  /* overflow: hidden auto; */
  margin-right: 30.7px;
  display: flex;
  flex-direction: column;
  gap: 15.5px;

  &.ui-768 {
    display: none;
    margin-bottom: 69.1px;
    margin-top: 0px;

    @media screen and (max-width: 1024px) {
      width: 183px;
    }
    @media screen and (max-width: 768px) {
      display: unset;
      height: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    margin-bottom: 20px;
    gap: 20px;
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    margin-bottom: 18px;
    gap: 18px;
  }
  @media screen and (max-width: 540px) {
    flex-direction: row;
    margin-bottom: 16px;
    gap: 16px;
  }

  div {
    /* display: flex;
    flex-direction: column;
    gap: 15.5px;
    margin: auto;
    max-width: 400px;
    height: auto;
    flex-wrap: wrap;
    padding: 10px; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  }

  &.mrt-80 {
    margin-top: 80px;
  }
`;

export const ItemtImage = styled.img`
  width: 100%;
  /* height: 250px; */
  object-fit: contain;
  cursor: pointer;
  border: 0.1px solid rgba(0, 0, 0, 0.075);

  &.active {
    /* border: 1px solid black; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 768px) {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    }
  }

  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    /* min-height: 250px; */
  }

  /* @media screen and (max-width: 768px) {
    width: 155px;
    height: auto;
    min-height: 250px;
  } */
`;

export const WpSelect = styled.div`
  padding-left: 15px;
  display: inline-flex;
  gap: 10px;

  .note-select {
    color: #59a5fc;
    font-family: "SpoqaHanSansNeo-Light";
    font-size: 18px;
  }
`;

export const ContainerEvent = styled.div`
  .event--tag {
    border-radius: 5px;
    background-color: #ff1744;
    width: 55px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-family: "SpoqaHanSansNeo-Bold";
    font-size: 11px;
    font-weight: 600;
  }
  .event--title {
    margin: 10px 0 45px;
    color: #0d0d0d;
    line-height: 1.21;
    font-family: "SpoqaHanSansNeo-Medium";
    font-size: 28px;
    font-weight: 500;
  }
  .event--price {
    color: #0d0d0d;
    line-height: 1.23;
    letter-spacing: 0.55px;
    font-family: "SpoqaHanSansNeo-Light";
    font-size: 22px;
    font-weight: 300;
    span {
      font-weight: 600;
      font-family: "SpoqaHanSansNeo-Bold";
    }
  }
  .event--price__spacing {
    margin: 28px 0 55px;
  }
  .event--button {
    background-color: #0d0d0d;
    width: 100%;
    border: none;
    height: 65px;
    line-height: 65px;
    text-align: center;
    color: #fff;
    font-family: "SpoqaHanSansNeo-Bold";
    font-size: 16px;
    font-weight: 600;
    &:hover,
    :active,
    :focus {
      background-color: #0d0d0d;
      border-color: #0d0d0d;
      color: #ffffff;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
    }

    &[disabled] {
      color: #ffffff;
      background-color: #ebebeb;
      cursor: not-allowed;
    }
  }
`;
