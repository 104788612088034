import { combineReducers } from "redux";
import popupReducer from "./popupReducer";
import userReducer from "./userReducer";
import searchReducer from "./searchReducer";
import filterLeftMenuReducer from "./filterLeftMenuReducer";
import orderArtReducer from "./orderArtReducer";
import selectReducer from "./selectReducer";
import getUserReducer from "./getUserReducer";
import getCategoriesReducer from "./getCategoriesReducer";
import getCategoriesTermReducer from "./getCategoriesTermReducer";
import typeLoginSnsReducer from "./typeLoginSnsReducer";

export default combineReducers({
  popup: popupReducer,
  user: userReducer,
  searchBar: searchReducer,
  filterLeftMenu: filterLeftMenuReducer,
  artOrder: orderArtReducer,
  selectBar: selectReducer,
  dataUser: getUserReducer,
  dataCategoriesMarket: getCategoriesReducer,
  dataCategoriesTerm: getCategoriesTermReducer,
  dataTypeLoginSns: typeLoginSnsReducer,
});
