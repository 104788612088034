import React from "react";
import * as S from "./style";

interface Props {
  percent?: number;
}

function ProgressBar({ percent }: Props): React.ReactElement {
  return (
    <S.ProgressBar
      className="percent-progress"
      strokeColor={"#056FDA"}
      showInfo={false}
      percent={percent}
      trailColor={"#F3F3F3"}
    />
  );
}

export default ProgressBar;
