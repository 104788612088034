import { fetchEventSource } from "@microsoft/fetch-event-source";
import { notification } from "antd";
import { BASE_URL } from "libs/axios";
import { getLocalStorage } from "utils/localStorage";
import { GETME } from "utils/localStorage/type";

let acToken = getLocalStorage(GETME, 0);
let ctrl = new AbortController();

export function initForFetchEventSource(accToken?: string, reCall?: boolean) {
  // setTimeout(() => {
  //   console.log("close connection")
  //   ctrl.abort("hmmm")
  // }, 10000);

  if (reCall) {
    ctrl.abort();
    ctrl = new AbortController();
  }
  fetchEventSource(`${BASE_URL}/sse/stream`, {
    headers: {
      Authorization: `Bearer ${accToken ?? acToken?.accessToken}`,
      "Cache-Control": "no-cache",
    },
    method: "GET",
    onmessage(ev) {
      console.log(ev);
      if (ev.event === "COMMON_MESSAGE") {
        notification.open({
          message: JSON.parse(ev.data)?.message,
          placement: "bottomLeft",
        });
      }
    },
    signal: ctrl.signal,
  });
}
