import moment from "moment";
import React from "react";

/** Utils */

/** Local Style */
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import * as S from "./style";

interface Props {
  noNumber: any;
  data: any;
  gotToDetail?: () => void;
}

const ArtTop10CardItem = ({ noNumber, data, gotToDetail }: Props) => {
  const { t } = useTranslation();
  return (
    <S.CardContent onClick={gotToDetail}>
      <div className="card-head">
        <div className="card-head__num">TOP {noNumber ?? 1}</div>
        <div className="card-head__title">
          <span>{data?.authorName}</span>
          {/* <span>{data?.productName}</span> */}
        </div>
      </div>
      <div className="card-content">
        <div className="card-content__author">{data?.productName}</div>
        {/**Untitled, 1999 */}
        <div className="card-content__saleprice">
          낙찰가: {moneyConvertLang(data?.price)}
        </div>
        <div className="card-content__more">
          <span>
            {t("TOP10_DATE")}:{" "}
            {data?.tradeDay
              ? moment(data?.tradeDay ?? "").format("YYYY.MM.DD")
              : ""}
          </span>
        </div>
        <div className="card-content__place">
          {t("TOP10_HOUSE")}:{" "}
          {data?.exhibitionDestination?.length > 35
            ? `${data?.exhibitionDestination.substring(0, 35)}...`
            : data?.exhibitionDestination}
        </div>
      </div>
    </S.CardContent>
  );
};

export default ArtTop10CardItem;
